<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form @submit.prevent="handleSubmit(submitHandler)" @reset.prevent="">
      <!-- Name -->
      <validation-provider #default="validationContext" name="Логин" rules="required|alpha-num|min:5">
        <b-form-group label="Логин" label-for="name">
          <b-form-input id="name" v-model="formData.name" :state="getValidationState(validationContext)"
                        trim
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Email -->
      <validation-provider #default="validationContext" name="Email" rules="required|email">
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="formData.email" :state="getValidationState(validationContext)"
                        trim
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Role -->
      <validation-provider #default="validationContext" name="Роль" rules="required">
        <b-form-group label="Роль" label-for="role">
          <v-select input-id="role" v-model="formData.role" :options="roles" :reduce="val => val.value"
                    :clearable="false"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Password -->
      <b-form-group>
        <validation-provider #default="validationContext" :rules="passwordRules">
          <b-input-group label="Пароль" label-for="password">
            <b-form-input id="password" v-model="formData.password"
                          :state="getValidationState(validationContext)" :type="passwordFieldType" trim
                          placeholder="............"
            />
            <b-input-group-append is-text>
              <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <div class="d-flex mt-2">
        <b-button variant="primary" class="mr-2" type="submit">
          {{ isCreate ? 'Добавить' : 'Сохранить' }}
        </b-button>
        <b-button type="button" variant="outline-secondary" @click="cancelHandler">
          Отмена
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { roles } from './usersData'
import { required, alphaNum, email, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

const emit = defineEmits(['submit', 'cancel'])
const props = defineProps({
  userData: {
    type: Object,
    default: () => ({})
  }
})

const formData = ref(Object.assign({
  password: '',
  re_password: '',
}, props.userData))
const submitHandler = () => {
  emit('submit', formData.value)
}
const cancelHandler = () => {
  emit('cancel')
}

const isCreate = computed(() => !formData.value.id)

const passwordRules = computed(() => isCreate.value ? 'required|min:5' : 'min:5')
const passwordFieldType = ref('password')
const passwordToggleIcon = computed(() => passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon')
const togglePasswordVisibility = () => {
  passwordFieldType.value = passwordFieldType.value == 'password' ? 'text' : 'password'
}

const {
  refFormObserver,
  getValidationState
} = formValidation(() => {
})
</script>
