<template>
<b-badge :variant="role.color">
  {{ role.label }}
</b-badge>
</template>
<script setup>
import { defineProps, ref } from 'vue'
// eslint-disable-next-line no-unused-vars
import { BBadge } from 'bootstrap-vue'

const props = defineProps({
  rolesList: {
    type: Array,
    required: true,
  },
  userRole: {
    type: String,
    required: true,
  },
})
const role = ref({
  text: '',
  value: '',
  color: 'secondary',
})
// eslint-disable-next-line no-restricted-syntax
for (const i in props.rolesList) {
  if (props.rolesList[i].value === props.userRole) {
    role.value = Object.assign(role.value, props.rolesList[i])
  }
}
</script>
