import { dateFormat } from '@/libs/filters'

export const roles = [
  {
    value: 'superuser',
    label: 'Администратор',
    color: 'primary',
  },
  {
    value: 'manager',
    label: 'Менеджер',
    color: 'warning',
  },
  {
    value: 'accountant',
    label: 'Бухгалтер',
    color: 'info',
  },
  {
    value: 'user',
    label: 'Пользователь',
    color: 'success',
  },
  {
    value: 'disabled',
    label: 'Отключен',
    color: 'secondary',
  },
]

export const tableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'name',
    label: 'Логин',
    sortable: true,
  },
  {
    key: 'email',
    sortable: true,
  },
  {
    key: 'role',
    label: 'Роль',
    sortable: true,
  },
  {
    key: 'created_at',
    label: 'Добавлен',
    sortable: true,
    formatter(value) {
      return dateFormat(value, true, false)
    },
  },
  {
    key: 'actions',
    label: '',
  },
]
